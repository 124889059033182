import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next"; 

function WeOffer() {
  const { t } = useTranslation()
  return (
    <section className="m-weOffer">
      <div className="_wr">
        <div className="m-weOffer__top">
          <h2>{t('services.appDev.offer.headline')}</h2>
          <span className="a-line -red"></span>
        </div>

        <div className="_w">
          <div className="_m6">
            <div>
              <p className="m-weOffer__text -mb">{t('services.appDev.offer.firstText')}</p>
              <p className="m-weOffer__text">{t('services.appDev.offer.secondText')}</p>
            </div>
          </div>

          <div className="_m6 -mt">
            <div>
              <p className="m-weOffer__text -mb">{t('services.appDev.offer.thirdText')}</p>
              <p className="m-weOffer__text">{t('services.appDev.offer.fourthText')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WeOffer;
