import React from "react";
import OfferCard from "./offer-card";

const Offer = ({ offerContent }) => {
  return (
    <section className="m-offer">
      <div className="_wr">
        <div className="_w jcc">
          {offerContent.map((item, i) => <OfferCard item={item} key={i} />)}
        </div>
      </div>
    </section>
  );
};

export default Offer;
