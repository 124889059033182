import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const LIMIT = 280;


const OfferCard = ({ item }) => {
    const [showMore, setShowMore] = useState(false);
    const { t } = useTranslation()
    const renderText = value => {
        if (showMore) {
            return value;
        } else {
            return `${value.substring(0, LIMIT)}...`;
        }
    };

    return (
        <div className="_m10  _l5  m-offer__itemContent">
            <div className="m-offer__item">
                <div className="m-offer__item--top">
                    <div className="m-offer__item--icon">
                        {item.icon}
                    </div>
                    <div
                        className="m-offer__item--title"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                </div>
                <div className="m-offer__item--textContent">
                    <p className="m-offer__item--text">{renderText(item.text)}</p>
                    <span
                        className="a-btn -redBorder a-hover -effectThree"
                        onClick={() => setShowMore(!showMore)}
                    >
                        {t(showMore ? 'buttons.readLess' : 'buttons.readMore')}
                        <span className="a-arrow -long"></span>
                    </span>
                </div>
            </div>
        </div>
    )
};

export default OfferCard;
